<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <template>
      <v-row>
        <v-col>
            <v-btn
              color="primary"
              @click="addCampaign()"
            >
              Add Campaign
            </v-btn>
        </v-col>
      </v-row>    
      <template v-if="monthlyNote">
        <v-row>
          <v-col>
            <center>
              <h4>
                {{ this.monthlyNote}}
              </h4>
            </center>
          </v-col>
        </v-row> 
      </template>        
      <div>
        <v-sheet
          tile
          height="54"
          class="d-flex"
        >
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.prev()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar
            flat
          >
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
          </v-toolbar>
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet class="mt-3">
          <v-calendar
            ref="calendar"
            v-model="value"            
            :weekdays="weekday"
            :type="type"
            :events="events"
            :event-overlap-mode="mode"
            :event-overlap-threshold="30"
            @change="getEvents"
          >
            <template v-slot:day-label="{ date, day }">
              {{ day}}
              <br/>
              <br/>

              <template v-if="getSales(date)">
                <v-chip
                  color="primary"
                  text-color="white">
                  {{ getNewSales(date) }} New / {{ getUsedSales(date) }} Used
                </v-chip>
              </template>
            </template>
            <template v-slot:day="{ date }">
              <v-tooltip bottom>
                <template v-slot:activator="{ attrs, on }">
                  <v-img
                    :src="getWeather(date).icon"
                    max-width="50"
                    v-bind="attrs"
                    v-on="on"
                  ></v-img>
                </template>
                <span> {{ getWeather(date).description }}</span>
              </v-tooltip>
              {{ getHolidays(date) }}
              <br/>
              <template v-for="(campaign) in getCampaigns(date)">
                <v-chip
                  :key="campaign.id"
                  label
                  small
                  class="ma-1"
                  @click="showCampaign(campaign)"
                >
                  {{ campaign.name }}
                </v-chip>
              </template>
            </template>
          </v-calendar>
        </v-sheet>
      </div>
    </template>
    
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="showCampaignDialog"
      persistent
      max-width="600"
      @keydown.esc="showCampaignDialog = false"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Marketing Campaign
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="currentCampaign.name"
            label="Name"
            required
            :rules="[v => !!v || 'Campaign name is required']"
            outlined
          ></v-text-field>


          <v-select
            v-model="currentCampaignVendorId"
            :items="vendors"
            label="Vendor"
            outlined
            required
            :rules="[v => !!v || 'Vendor is required']"
            item-text="name"
            item-value="id"
          />

          <v-menu
            v-model="startDateMenu"
            :open-on-focus="true"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedStartDate"
                label="Start Date"
                readonly
                outlined
                required
                :rules="[v => !!v || 'Start date is required']"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="currentCampaignStartDate"
              @input="startDateMenu = false"
            />
          </v-menu>

          <v-menu
            v-model="endDateMenu"
            :open-on-focus="true"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedEndDate"
                label="End Date"
                readonly
                outlined
                required
                :rules="[v => !!v || 'End date is required']"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="currentCampaignEndDate"
              @input="endDateMenu = false"
            />
          </v-menu>   
          <v-text-field
            v-model="currentCampaign.cost"
            label="Cost"
            outlined
          ></v-text-field> 
          <v-text-field
            v-model="currentCampaign.link"
            label="Link"
            outlined
          ></v-text-field> 
          <v-textarea
            v-model="currentCampaign.notes"
            label="Notes"
            outlined
            rows="3"
          ></v-textarea>                        
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="saveVendorCampaign()"
          >
            Save
          </v-btn>
          <v-btn
            @click="showCampaignDialog = false"
          >
            Cancel
          </v-btn>
          <template v-if="this.currentCampaign && this.currentCampaign.id">
            <v-btn
              color="red"
              @click="deleteVendorCampaign()"
            >
              Delete
            </v-btn>
          </template>        
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import MarketingService from '@/services/MarketingService.js'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'
  import { VBtn } from 'vuetify/lib'

  export default {
    name: 'MarketingCalendar',
    components: {
      ErrorMessage,
      CalendarBtn: {
        extends: VBtn,

        props: {
          color: {
            type: String,
            default: 'secondary',
          },
          minWidth: {
            type: Number,
            default: 0,
          },
          rounded: {
            type: Boolean,
            default: true,
          },
        },

        computed: {
          classes () {
            return {
              ...VBtn.options.computed.classes.call(this),
              'mx-1': true,
              'text-lowercase': true,
            }
          },
        },
      },
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      },
    },
    mixins: [formatterMixin, navigationMixin, titleMixin],
    data () {
      return {
        loadingMessage: 'Loading',
        showErrorDialog: false,
        loading: true,
        error: '',
        expanded: [],
        value: '',
        type: 'month',
        mode: 'stack',
        weekday: [0, 1, 2, 3, 4, 5, 6],
        events: [],
        currentMonth: null,
        currentYear: null,
        dailyData: [],
        showCampaignDialog: false,
        currentCampaign: {},
        startDateMenu: true,
        endDateMenu: true,
        currentCampaignStartDate: null,
        currentCampaignEndDate: null,
        currentCampaignVendorId: null,
        vendors: [],
        monthlyNote: null,
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
      formattedStartDate:
        {
          get () {
            if (this.currentCampaign && this.currentCampaignStartDate) {
              return this.formatIsoDate(this.currentCampaignStartDate)
            }
          },
          set (value) {
            this.currentCampaignStartDate = value
          },
        },
      formattedEndDate:
        {
          get () {
            if (this.currentCampaign && this.currentCampaignEndDate) {
              return this.formatIsoDate(this.currentCampaignEndDate)
            }
          },
          set (value) {
            this.currentCampaignEndDate = value
          },
        },        
    },
    watch: {
      accountId () {
        this.loadEvents()
      },
      watch: {
      },
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },
    created () {
      // this.loadEvents()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadEvents: function () {
        this.loadingMessage = 'Loading Marketing Calendar'
        this.loading = true
        var data = {
          month:  this.currentMonth,
          year:   this.currentYear,
        }
        MarketingService.getCalendar(data)
          .then(response => {
            console.log(response.data)
            this.dailyData = response.data.calendar.daily_data
            this.vendors = response.data.vendors
            this.monthlyNote = response.data.monthly_note
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      saveVendorCampaign: function () {
        this.loading = true
        var data = {
          vendorCampaignId: this.currentCampaign.id,
          vendorId: this.currentCampaignVendorId,
          startDate: this.currentCampaignStartDate,
          endDate: this.currentCampaignEndDate,
          name: this.currentCampaign.name,
          cost: this.currentCampaign.cost,
          link: this.currentCampaign.link,
          notes: this.currentCampaign.notes,
        }
        if (data.vendorCampaignId) {
          this.loadingMessage = "Updating Campaign"
          MarketingService.updateVendorCampaign(data)
            .then(response => {
              this.loading = false
              this.showCampaignDialog = false
              this.loadEvents()
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        } else {
          this.loadingMessage = "Creating Campaign"
          MarketingService.createVendorCampaign(data)
            .then(response => {
              this.loading = false
              this.showCampaignDialog = false
              this.loadEvents()
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
      deleteVendorCampaign: function () {
        if (confirm('Are you sure you want to delete this campaign') && this.currentCampaign) {
          this.loadingMessage = "Deleting Campaign"
          this.loading = true
          var data = {
            vendorCampaignId: this.currentCampaign.id,
            vendorId: this.currentCampaignVendorId,
          }
          MarketingService.deleteVendorCampaign(data)
            .then(response => {
              this.loading = false
              this.showCampaignDialog = false
              this.loadEvents()
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
      getEvents (params) {
        if (params) {
          var d = new Date(params.end.date)
          this.currentMonth = d.getMonth() + 1
          this.currentYear = d.getFullYear() 
          this.loadEvents()
        }
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      }, 
      getWeather(date) {
        var currentDay = this.dailyData.filter(item => item.day == date)[0]
        if (currentDay) {
          return currentDay.weather
        }
        else { return null}
      },
      getHolidays(date) {
        var currentDay = this.dailyData.filter(item => item.day == date)[0]
        if (currentDay) {
          return currentDay.holidays
        }
        else { return null}
      },
      getSales(date) {
        var currentDay = this.dailyData.filter(item => item.day == date)[0]
        if (currentDay) {
          if (currentDay.sales > 0) {
            return currentDay.sales
          }
          else { return null}
        }
        else { return null}
      },  
      getNewSales(date) {
        var currentDay = this.dailyData.filter(item => item.day == date)[0]
        if (currentDay) {
          if (currentDay.sales > 0) {
            return currentDay.new
          }
          else { return null}
        }
        else { return null}
      },  
      getUsedSales(date) {
        var currentDay = this.dailyData.filter(item => item.day == date)[0]
        if (currentDay) {
          if (currentDay.sales > 0) {
            return currentDay.used
          }
          else { return null}
        }
        else { return null}
      },                  
      getCampaigns(date) {
        var currentDay = this.dailyData.filter(item => item.day == date)[0]
        if (currentDay) {
          return currentDay.campaigns
        }
        else { return null}
      },      
      showCampaign(campaign) {
        this.currentCampaign = campaign
        this.currentCampaignStartDate = this.formatJSDate(new Date(Date.parse(campaign.start_date)))
        this.currentCampaignEndDate = this.formatJSDate(new Date(Date.parse(campaign.end_date))) 
        this.currentCampaignVendorId = campaign.advertiser_id    
        this.showCampaignDialog = true
      },
      addCampaign() {
        this.currentCampaign = {}
        this.currentCampaignStartDate = null
        this.currentCampaignEndDate = null
        this.currentCampaignVendorId = null
        this.showCampaignDialog = true
      },
    },
  }
</script>
<style>
  .v-calendar-weekly {
    display: block !important
  }
</style>

