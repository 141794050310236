import axios from 'axios'

export default {
  getCalendar (data) {
    return axios.get(
      '/marketing/calendar?month=' + data.month + '&year=' + data.year
    )
  },
  createVendorCampaign (data) {
    return axios.post(
      '/marketing/vendor_campaigns/' + data.vendorCampaignId + '/create?vendor_id=' + data.vendorId + '&start_date=' + data.startDate + '&end_date=' + data.endDate +
      '&name=' + data.name + '&cost=' + data.cost + '&notes=' + data.notes + '&link=' + data.link,
    )
  },  
  updateVendorCampaign (data) {
    return axios.patch(
      '/marketing/vendor_campaigns/' + data.vendorCampaignId + '/update?vendor_id=' + data.vendorId + '&start_date=' + data.startDate + '&end_date=' + data.endDate +
      '&name=' + data.name + '&cost=' + data.cost + '&notes=' + data.notes + '&link=' + data.link,
    )
  },
  deleteVendorCampaign (data) {
    return axios.delete(
      '/marketing/vendor_campaigns/' + data.vendorCampaignId + '/delete?vendor_id=' + data.vendorId,
    )
  }
}
